<template>
  <div class="about">
    <div class="log_box_main">
      <el-row class="log_box1">
        <el-col :span="12" :class="active1 == 0 ? 'dq' : ''">
          <div @click="changeTab(0)">个人会员注册</div>
        </el-col>
        <el-col
          :span="12"
          :class="active1 == 1 ? 'dq' : ''"
          @click="active1 = 1"
        >
          <div @click="changeTab(1)">企业会员注册</div>
        </el-col>
      </el-row>
      <div v-if="$width > 767">
        <div>
          <el-row class="formrow" v-for="(item, k) of form" :key="'k' + k">
            <el-col :span="6" :offset="1" class="formname">{{
              item.label
            }}</el-col>
            <el-col
              :span="14"
              v-if="item.label != '*验证码' && item.label != '*性别'"
            >
              <el-input
                :type="item.type"
                class="inner"
                v-model="item.value"
                :placeholder="item.placeholder"
              ></el-input>
            </el-col>

            <el-col :span="14" v-if="item.label == '*性别'">
              <el-select
                v-model="item.value"
                placeholder="请选择"
                class="inner"
                style="width: 100%"
              >
                <el-option
                  v-for="sex in [
                    { label: '男', value: 1 },
                    { label: '女', value: 0 },
                  ]"
                  :key="sex.value"
                  :label="sex.label"
                  :value="sex.value"
                >
                  <span style="float: left">{{ sex.label }}</span>
                </el-option>
              </el-select>
            </el-col>
            <el-col :span="8" v-if="item.label == '*验证码'">
              <el-input
                :type="item.type"
                class="inner"
                v-model="item.value"
                :placeholder="item.placeholder"
              ></el-input>
            </el-col>
            <el-col :span="6" v-if="item.label == '*验证码'">
              <img
                height="40px"
                style="float: right"
                :src="'data:image/png;base64,' + capcha.img"
              />
            </el-col>
          </el-row>
        </div>

        <div style="color: #ffffff; text-align: center; margin-bottom: 30px">
          <input type="checkbox" name="genre" id="action" value="action" />
          点击“立即注册”，即表示您同意并愿意遵守天润用户协议 和 隐私政策
        </div>

        <el-row class="formrow">
          <el-col :span="18" :offset="3" class="btn" @click.native="regis">
            注册
          </el-col>
        </el-row>

        <div style="padding: 10px 30px 30px 0; text-align: right; color: white">
          <a style="color: white" href="/login/">已有账号立即登录</a>
        </div>
      </div>

      <div v-if="$width < 767">
        <div v-if="active1 == 0">
          <el-row class="formrow" v-for="(item, k) of form" :key="'k' + k">
            <el-col :span="8" class="formname">{{ item.label }}</el-col>
            <el-col :span="14" v-if="item.label != '*验证码'">
              <el-input
                class="inner"
                v-model="item.value"
                :placeholder="item.placeholder"
              ></el-input>
            </el-col>

            <el-col :span="8" v-if="item.label == '*验证码'">
              <el-input
                class="inner"
                v-model="item.value"
                :placeholder="item.placeholder"
              ></el-input>
            </el-col>
            <el-col :span="6" v-if="item.label == '*验证码'"> </el-col>
          </el-row>
        </div>

        <div v-if="active1 == 1">
          <el-row class="formrow" v-for="(item, k) of form1" :key="'k' + k">
            <el-col :span="8" class="formname">{{ item.label }}</el-col>
            <el-col :span="14" v-if="item.label != '*验证码'">
              <el-input
                class="inner"
                v-model="item.value"
                :placeholder="item.placeholder"
              ></el-input>
            </el-col>

            <el-col :span="8" v-if="item.label == '*验证码'">
              <el-input
                class="inner"
                v-model="item.value"
                :placeholder="item.placeholder"
              ></el-input>
            </el-col>
            <el-col :span="6" v-if="item.label == '*验证码'"> </el-col>
          </el-row>
        </div>

        <div style="color: #ffffff; text-align: center; margin-bottom: 30px">
          <input type="checkbox" name="genre" id="action" value="action" />
          点击“立即注册”，即表示您同意并愿意遵守天润用户协议 和 隐私政策
        </div>

        <el-row class="formrow">
          <el-col :span="18" :offset="3" class="btn"> 注册 </el-col>
        </el-row>

        <div style="padding: 10px 30px 30px 0; text-align: right; color: white">
          <a style="color: white" href="/login/">已有账号立即登录</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api/api";

export default {
  name: "trheader",
  data() {
    return {
      active1: 0,
      action: false,
      form: [],
      form1: [
        {
          label: "*用户名",
          placeholder: "请输入用户名",
          value: null,
          type: "text",
        },
        {
          label: "*密码",
          placeholder: "请输入密码",
          value: null,
          type: "password",
        },
        {
          label: "*确认密码",
          placeholder: "请再次输入密码",
          value: null,
          type: "password",
        },
        {
          label: "*姓名",
          placeholder: "请输入姓名",
          value: null,
          type: "text",
        },
        {
          label: "*身份证号码",
          placeholder: "请输入身份证号码",
          value: null,
          type: "number",
        },
        { label: "*性别", placeholder: "请输入性别", value: null },
        {
          label: "*邮箱",
          placeholder: "请输入邮箱",
          value: null,
          type: "text",
        },
        {
          label: "*地址",
          placeholder: "请输入地址",
          value: null,
          type: "text",
        },
        {
          label: "*手机",
          placeholder: "请输入手机",
          value: null,
          type: "number",
        },
        {
          label: "*验证码",
          placeholder: "请输入验证码",
          value: null,
          type: "number",
        },
      ],
      form2: [
        {
          label: "*用户名",
          placeholder: "请输入用户名",
          value: null,
          type: "text",
        },
        {
          label: "*密码",
          placeholder: "请输入密码",
          value: null,
          type: "password",
        },
        {
          label: "*确认密码",
          placeholder: "请再次输入密码",
          value: null,
          type: "password",
        },
        {
          label: "*企业名称",
          placeholder: "请输入企业名称",
          value: null,
          type: "text",
        },
        {
          label: "*营业执照号码",
          placeholder: "请输入营业执照号码",
          value: null,
          type: "text",
        },
        {
          label: "*性别",
          placeholder: "请输入性别",
          value: null,
          type: "text",
        },
        {
          label: "*邮箱",
          placeholder: "请输入邮箱",
          value: null,
          type: "text",
        },
        {
          label: "*地址",
          placeholder: "请输入地址",
          value: null,
          type: "text",
        },
        {
          label: "*手机",
          placeholder: "请输入手机",
          value: null,
          type: "number",
        },
        {
          label: "*验证码",
          placeholder: "请输入验证码",
          value: null,
          type: "number",
        },
      ],
      capcha: {},
    };
  },
  components: {},
  methods: {
    changeTab(i) {
      this.active1 = i;
      this.form = Object.assign({}, i == 0 ? this.form1 : this.form2);
    },
    getCapcha() {
      api.captchaImage().then((res) => {
        if (res.code == 200) {
          this.capcha = res;
        }
      });
    },
    regis() {
      if (this.form[1].value != this.form[2].value) {
        this.$message.error("两次密码不一致，请重新输入密码");
        return;
      }
      let regisForm = {
        address: this.form[7].value,
        email: this.form[6].value,
        idCard: this.form[4].value,
        nickName: this.form[3].value,
        password: this.form[1].value,
        phonenumber: this.form[8].value,
        sex: this.form[5].value,
        userName: this.form[0].value,
        code: this.form[9].value,
        uuid: this.capcha.uuid,
      };
      if (!regisForm.userName) {
        this.$message.error("请输入用户名");
        return;
      }
      if (!regisForm.password) {
        this.$message.error("请输入密码");
        return;
      }
      if (!regisForm.nickName) {
        this.$message.error("请输入姓名");
        return;
      }
      if (!regisForm.idCard) {
        this.$message.error("请输入身份证");
        return;
      }
      if (regisForm.sex === null) {
        this.$message.error("请输入性别");
        return;
      }
      if (!regisForm.email) {
        this.$message.error("请输入邮箱");
        return;
      }
      if (!regisForm.address) {
        this.$message.error("请输入地址");
        return;
      }
      if (!regisForm.phonenumber) {
        this.$message.error("请输入手机");
        return;
      }
      if (!regisForm.code) {
        this.$message.error("请输入验证码");
        return;
      }
      api.regis(regisForm).then((res) => {
        if (res.code == 500) {
          if (res.msg == "验证码已失效") {
            this.getCapcha();
          } else {
            this.$message.error(res.msg);
          }
          return;
        }
        if (res.code == 200 && res.msg == "操作成功") {
          this.$message.success("登录成功");
          setTimeout(() => {
            location.href = "/login";
          }, 1500);
        }
      });
    },
  },
  mounted() {
    this.getCapcha();
    this.changeTab(0);
  },
};
</script>
<style scoped>
.about {
  background: url("/images/logbg.jpeg");
}
.log_box_main {
  width: 100%;
  max-width: 600px;
  padding: 20px 0px;
  margin: 0px auto;
}
.log_box1 {
  font-size: 24px;
  color: #fff;
  text-align: center;
  padding-bottom: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid #dedede;
  cursor: pointer;
}

.log_box1 .dq {
  color: #ff6d21;
}
.formrow {
  margin-bottom: 25px;
}
.formrow .btn {
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: #ec6526;
  color: #fff;
  font-size: 18px;
}
.formname {
  color: white;
  height: 40px;
  line-height: 40px;
  text-align: right;
  padding-right: 20px;
}
.inner {
  opacity: 0.7;
  /* background: #f4f4f4; */
}
@media only screen and (min-width: 320px) and (max-width: 767px) {
  .formrow .btn {
    height: 40px;
    line-height: 40px;
  }
}
</style>
